import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const CustomLink = ({to, title}) => {
  const {t} = useTranslation();

  return (
    <NavLink
      className='custom-link'
      to={to}
    >
      {title ?? t('customLink')}
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M14.525 17.95L13.45 16.9L17.65 12.7H4.5V11.2H17.65L13.425 6.97499L14.5 5.92499L20.525 11.95L14.525 17.95Z" fill="#7eca35"/>
      </svg>
    </NavLink>
  )
}

export {CustomLink}
