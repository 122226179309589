import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Context} from "../../common/scripts/context";
import {useWindowSize} from "../../common/hooks/useWindowSize";
import {Paragraphs} from "../../components/Paragraphs";
import {CustomCarousel} from "../../components/CustomCarousel";
import {PageNav} from "../../components/PageNav";
import {Projects} from "../../components/Projects";
import {activateScrollPage} from "../../common/scripts/activateScrollPage";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {Chips} from "../../components/Chips";

import customerPhotoImage from "../../common/images/customer-photo.png";
import platformProjectImage from "../../common/images/platform-project.png";
import cagesProjectImage from "../../common/images/cages-projest.png";
import dashboardProjectImage from "../../common/images/dashboard-projest.png";

const BusinessIntelligencePage = () => {
  const { height } = useWindowSize();
  const {t, i18n} = useTranslation();
  const defaultMessage = t('businessIntelligencePage', {returnObjects: true});
  const defaultReviewsMessage = t('businessIntelligenceReviewsCarousel', {returnObjects: true});

  const {setFooterTheme} = useContext(Context);
  const [activeLink, setActiveLink] = useState();
  const [messages, setMessages] = useState(defaultMessage);
  const [reviewsMessages, setReviewsMessages] = useState(defaultReviewsMessage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFooterTheme('light align-left');
  }, [setFooterTheme]);

  useEffect(() => {
    activateScrollPage(height, activeLink, setActiveLink);
  }, [activeLink, setActiveLink, height]);

  useEffect(() => {
    setMessages(defaultMessage);
    setReviewsMessages(defaultReviewsMessage)
  }, [i18n.language, t]);

  const verticalMenuLinkArray = [
    {href: "#main", title: messages.menuLinks[0]},
    {href: "#technologies", title: messages.menuLinks[1]},
    /*{href: "#projects", title: messages.menuLinks[2]},*/
    {href: "#about", title: messages.menuLinks[2]},
    {href: "#reviews", title: messages.menuLinks[3]},
  ];

  const reviewsPhoto = [
    {imgHref: customerPhotoImage},
    {imgHref: customerPhotoImage},
    {imgHref: customerPhotoImage}
  ];

  const getReviewsList = useCallback(() => {
    return reviewsMessages.map((el, index) => ({
        name: el.name,
        info: el.info,
        description: el.description,
        imgHref: reviewsPhoto[index]?.imgHref
      }));
    }, [reviewsMessages],
  );

  const projectsPhoto = [
    {imgHref: platformProjectImage},
    {imgHref: cagesProjectImage},
    {imgHref: dashboardProjectImage},
  ]

  const getProjectsList = useCallback(() => {
      return messages.projects.projectsList.map((el, index) => ({
        blockSize: 'small',
        imgHref: projectsPhoto[index].imgHref,
        title: el.title,
        subtitle: el.subtitle,
        description: el.description,
        link: `/services/data-analytics/projects/${el.linkTitle}`
      }));
    }, [],
  );

  return (
    <div className='business-intelligence-page page'>
      <div className='business-intelligence-page__blocks'>
        <div className='business-intelligence-page__info-block'>
          <section className='business-intelligence-page__main' id="main">
            <Breadcrumbs/>

            <h2>{t('businessIntelligencePage.main.title')}</h2>
            <p>{t('businessIntelligencePage.main.description')}</p>

          {/*<div className='business-intelligence-page__main-video video-container'>
            <iframe
            className="video-content"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/9rmjsDbMqbM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            />
            </div>*/}
          </section>

          <section className='business-intelligence-page__technologies' id="technologies">
            <h3>{t('businessIntelligencePage.technologies.title')}</h3>
            <p>{t('businessIntelligencePage.technologies.description')}</p>

            <div className='business-intelligence-page__technologies-chips'>
              <Chips/>
            </div>
          </section>

          {/* <section className='business-intelligence-page__projects' id="projects">
            <h3>{t('businessIntelligencePage.projects.title')}</h3>
            <Projects projectsList={getProjectsList()} size="small"/>
          </section> */}

          <section className='business-intelligence-page__about' id="about">
            <Paragraphs paragraphsList={messages.about} theme="dark"/>
          </section>

          <section className='business-intelligence-page__reviews' id="reviews">
            <CustomCarousel
              carouselList={getReviewsList()}
              title={t('businessIntelligencePage.reviews.title')}
            />
          </section>
        </div>

        <div className='business-intelligence-page__menu-block'>
          <PageNav
            linkArray={verticalMenuLinkArray}
            activeLink={activeLink}
            link="/services/data-analytics/contact-us"
          />
        </div>
      </div>
    </div>
  )
}

export {BusinessIntelligencePage}
