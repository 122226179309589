import {useContext} from "react";
import {NavLink} from "react-router-dom";
import {Context} from "../common/scripts/context";
import {useTranslation} from "react-i18next";

import darkPartner1 from '../common/images/partner1-dark.svg';
import darkPartner2 from '../common/images/partner2-dark.svg';
import darkPartner3 from '../common/images/partner3-dark.svg';
import darkPartner4 from '../common/images/partner4-dark.svg';
import lightPartner1 from '../common/images/partner1-light.png';
import lightPartner2 from '../common/images/partner2-light.svg';
import lightPartner3 from '../common/images/partner3-light.svg';
import lightPartner4 from '../common/images/partner4-light.svg';

const Footer = () => {
  const {t} = useTranslation();
  const {footerTheme} = useContext(Context);

  return (
    <footer className={`footer ${footerTheme}`}>
      <div className="section-wrapper">
        <div className="footer-block">
          <div className="footer__contact">
            <a className="footer__contact-link" href="mailto: it@fossa.by">it@fossa.by</a>
            <br/>
            <a className="footer__contact-link" href="tel: +375256509716">+375 25 6509716</a>
            {/*<div className="footer__messengers-links">
              <a href="https://de.linkedin.com/company/fs-focus-strategy" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M32 16.9941C32 25.8307 24.8366 32.9941 16 32.9941C7.16344 32.9941 0 25.8307 0 16.9941C0 8.15758 7.16344 0.994141 16 0.994141C24.8366 0.994141 32 8.15758 32 16.9941ZM22.2042 26.3275H26.2222V18.0733C26.2222 14.5808 24.2425 12.8922 21.4772 12.8922C18.7106 12.8922 17.5463 15.0466 17.5463 15.0466V13.2905H13.6741V26.3275H17.5463V19.4839C17.5463 17.6502 18.3904 16.559 20.0061 16.559C21.4912 16.559 22.2042 17.6075 22.2042 19.4839V26.3275ZM6.66666 9.17768C6.66666 10.5062 7.73546 11.5834 9.05445 11.5834C10.3734 11.5834 11.4416 10.5062 11.4416 9.17768C11.4416 7.84917 10.3734 6.77197 9.05445 6.77197C7.73546 6.77197 6.66666 7.84917 6.66666 9.17768ZM11.0928 26.3275H7.05497V13.2905H11.0928V26.3275Z"
                        fill="white"/>
                </svg>
              </a>
              <a href="https://www.youtube.com/@FS-focus-strategy" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M0 16.9941C0 8.15758 7.16344 0.994141 16 0.994141C24.8366 0.994141 32 8.15758 32 16.9941C32 25.8307 24.8366 32.9941 16 32.9941C7.16344 32.9941 0 25.8307 0 16.9941ZM25.5316 23.3636C25.8627 23.0305 26.1004 22.6162 26.2209 22.1622C26.6666 20.4879 26.6666 16.9941 26.6666 16.9941C26.6666 16.9941 26.6666 13.5004 26.2209 11.826C26.1004 11.372 25.8627 10.9578 25.5316 10.6246C25.2006 10.2914 24.7878 10.0511 24.3347 9.92758C22.6713 9.47914 16 9.47914 16 9.47914C16 9.47914 9.32875 9.47914 7.66531 9.92758C7.21216 10.0511 6.7994 10.2914 6.46836 10.6246C6.13733 10.9578 5.89963 11.372 5.77906 11.826C5.33344 13.5004 5.33344 16.9941 5.33344 16.9941C5.33344 16.9941 5.33344 20.4879 5.77906 22.1622C5.89963 22.6162 6.13733 23.0305 6.46836 23.3636C6.7994 23.6968 7.21216 23.9372 7.66531 24.0607C9.32875 24.5091 16 24.5091 16 24.5091C16 24.5091 22.6713 24.5091 24.3347 24.0607C24.7878 23.9372 25.2006 23.6968 25.5316 23.3636ZM19 16.9941L14 19.9941V13.9941L19 16.9941Z"
                        fill="white"/>
                </svg>
              </a>

            </div> */}
          </div>
           <div className="footer__partner">
            <p>{t('footer.cards.title')}</p>

            {/*If you only need images*/}
          <div className={`footer__partner-block ${footerTheme === 'dark' ? 'light' : 'dark'}`}>
          <a href="https://simpleone.ru/" target="_blank" rel="noreferrer">
          <img src={footerTheme.includes('dark') ? lightPartner1 : darkPartner1}
              alt="Partner"/>
          </a>
          {/*<img src={footerTheme.includes('dark') ? lightPartner4 : darkPartner4}
              alt="Partner"/>*/}
              {/*<img src={footerTheme.includes('dark') ? lightPartner2 : darkPartner2} alt="Partner"/>*/}
           </div>

            {/*If you need cards*/}
            {/*<div className={`footer__partner-cards ${footerTheme.includes('dark') ? 'light' : 'dark'}`}>
              <div className="footer__partner-cards-item">
                <img src={footerTheme.includes('dark') ? lightPartner1 : darkPartner1} alt="Partner"/>
                <h3 className="green">{t('footer.cards.block1.title')}</h3>
                <p>{t('footer.cards.block1.name')}</p>
                <span>{t('footer.cards.block1.description')}</span>
              </div>

              <div className="footer__partner-cards-item">
                <img src={footerTheme.includes('dark') ? lightPartner1 : darkPartner1} alt="Partner"/>
                <h3 className="green">{t('footer.cards.block2.title')}</h3>
                <p>{t('footer.cards.block2.name')}</p>
                <span>{t('footer.cards.block2.description')}</span>
              </div>

              <div className="footer__partner-cards-item">
                <img src={footerTheme.includes('dark') ? lightPartner1 : darkPartner1} alt="Partner"/>
                <h3 className="orange">{t('footer.cards.block3.title')}</h3>
                <p>{t('footer.cards.block3.name')}</p>
                <span>{t('footer.cards.block3.description')}</span>
              </div>

              <div className="footer__partner-cards-item large-img">
                <img src={footerTheme.includes('dark') ? lightPartner2 : darkPartner2} alt="Partner"/>
              </div>

              <div className="footer__partner-cards-item large-img">
                <img src={footerTheme.includes('dark') ? lightPartner3 : darkPartner3} alt="Partner"/>
              </div>
            </div>*/}
         </div>
        </div>
        <div className="footer__policy">
          <p>{t('footer.info')}</p>
          {/* <span>&bull;</span>
            <NavLink to="/policy" target="_blank">{t('footer.policy')}</NavLink>
            <span>&bull;</span>
            <NavLink to="/impressum" target="_blank">{t('footer.terms')}</NavLink> */}
        </div>
      </div>
    </footer>
  )
}

export {Footer}
